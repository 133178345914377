export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/chat": [7,[2]],
		"/chat/[chat]": [8,[2]],
		"/discover": [9],
		"/home": [10],
		"/notes": [11],
		"/people": [12],
		"/settings": [13,[3]],
		"/settings/about": [14,[3]],
		"/settings/profile": [15,[3]],
		"/settings/relays": [16,[3]],
		"/spaces/[relay]": [17,[4,5]],
		"/spaces/[relay]/calendar": [19,[4,5]],
		"/spaces/[relay]/threads": [20,[4,5]],
		"/spaces/[relay]/threads/[id]": [21,[4,5]],
		"/spaces/[relay]/[room]": [18,[4,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';